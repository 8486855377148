// Application specific variables should be here

//
// Colors Definition
// color names are generated with http://chir.ag/projects/name-that-color/
//
$color-big-stone: #111e32;
$color-cello: #1d465a33;
$color-grey-smoke: #eef1f1;
$color-midnight: #00122d;

//
// Borders
//
$border-side-big-stone: 1px solid $color-big-stone;
$border-side-geyser: 1px solid $color-geyser;
$border-side-dark-denim: 1px solid $color-dark-denim;

$box-shadow-inset: inset 2px 0 0 0;

$pay-button-height: 40px;
$pay-button-vertical-margins: $quadruple-gap * 2;
$gap-for-pay-button: $pay-button-height + $pay-button-vertical-margins;
$gap-for-pay-button-special-offers: $pay-button-height + $pay-button-vertical-margins * 2;

//
// z-indexes
//
$z-index-external-chat-widget: 999;
$z-index-sidebar: $z-index-external-chat-widget + 1;
$z-index-footer: 2;

$header-height-mobile: calc(53px + env(safe-area-inset-top));
$header-height-tablet: 64px;

$bottom-navigation-height-mobile: 70px;
$bottom-navigation-height-tablet: 65px;

$banner-height-mobile: calc(56px + env(safe-area-inset-top));
$banner-height-tablet: 64px;
